body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
* {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
}

*:focus {
  outline: 0;
}

html,body, #root {
  height: 100%;
}

#root {
  display: flex;
  flex-direction: column;
}

body {
  -webkit-font-smoothing: antialiased;
}

body,input,button {
  font: 14px 'Roboto' , sans-serif;
}

a {
  text-decoration: none
}

ul {
  list-style: none;
}

button {
  cursor: pointer;
}

@font-face {
  font-family: 'Avenir Roman';
  src: url(./assets/Avenir-LT-Std/AvenirLTStd-Roman.otf);
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: 'Avenir Book';
  src: url(./assets/Avenir-LT-Std/AvenirLTStd-Book.otf);
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: 'Avenir Medium';
  src: url(./assets/Avenir-LT-Std/AvenirLTStd-Medium.otf);
  font-style: normal;
  font-weight: normal;
}
